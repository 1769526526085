import { ConfigProvider, Spin } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import ContentContainer from './components/ContentContainter/ContentContainer.jsx';
import { wsGetAlmatyPolygons } from './utils/webSocketConfig.js';
import { $loader, $webSocketConnected } from './models/webSocketModel/index.js';
import {
  $authStatus,
  $isDarkTheme,
  $userList,
  getUserListFx,
  setAuthStatusEv,
} from './models/authModel/index.js';
import { cookies } from './api/axiosinstance.js';
import { darkTheme, lightTheme } from './themeConfigs/themeConfigs.js';
import { $zoom10Hexagons } from './models/zoom10Model/index.js';
import { getDataPresetsFx } from './models/dataPresetsModel/index.js';
import { getZeroDataFx } from './models/zoom7Model/index.js';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const webSocketConnected = useUnit($webSocketConnected);
  const showLoader = useUnit($loader);
  const authStatus = useUnit($authStatus);
  const setAuth = useUnit(setAuthStatusEv);
  const isDarkTheme = useUnit($isDarkTheme);
  const zoom10Hexagons = useUnit($zoom10Hexagons);
  const getUserList = useUnit(getUserListFx);
  const userList = useUnit($userList);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/root');
    }
  }, [location]);

  useEffect(() => {
    if (webSocketConnected) {
      if (zoom10Hexagons.length === 0 && location.pathname === '/root') {
        wsGetAlmatyPolygons();
        window.download_start = performance.now();
      }
      if (location.pathname === '/user-list' && userList.length === 0) {
        getUserList();
      }
      // console.time('TIME:: zoom 8 polygons_v2');
      // console.time('TIME:: zoom 9 polygons_v2');
      // console.time('TIME:: download zoom 10 polygons');
      // wsGetAlmatyPolygonsV2();
    }
  }, [webSocketConnected, zoom10Hexagons, location]);

  useEffect(() => {
    if (!authStatus && location.pathname !== '/chat-modal') {
      const token = cookies.get('access_token');
      if (!token) {
        navigate('/login');
      } else {
        setAuth(true);
      }
    }
  }, [authStatus, location]);

  return (
    <ConfigProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <Spin spinning={showLoader} tip="Загружаем данные..." size="large">
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Spin>
    </ConfigProvider>
  );
}

export default App;
